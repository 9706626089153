import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import { FormattedDate, FormattedTime } from "react-intl"

const Event = ({
  event: {
    title,
    description,
    imageUrl,
    startTime,
    endTime,
    locationName,
    locationLink,
  },
}) => (
  <div
    css={css`
      display: flex;
      margin: 20px auto;
      padding: 30px 0px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.5);

      :last-child {
        border-bottom: none;
      }

      @media (max-width: 640px) {
        flex-direction: column;
      }
    `}
  >
    <div
      css={css`
        flex: 1.2;
        margin: 0px 0px 20px 0px;

        @media (max-width: 640px) {
          /* center the image on mobile */
          display: flex;
          justify-content: center;
        }
      `}
    >
      <img
        css={css`
          max-width: 90%;
        `}
        src={imageUrl}
        alt="event"
      />
    </div>
    <div
      css={css`
        flex: 3;
        font-size: 16px;
        color: #f8f2f2;
      `}
    >
      <h1
        css={css`
          margin: 0px 0px 10px 0px;
          flex: 3;
          text-transform: uppercase;
          color: #e5cccc;
          font-weight: 500;
        `}
      >
        {title}
      </h1>
      <div>
        <FormattedDate
          value={startTime}
          weekday="long"
          year="numeric"
          month="long"
          day="2-digit"
        />
      </div>
      <div>
        <FormattedTime value={startTime} /> - <FormattedTime value={endTime} />
      </div>
      <div>
        {locationName}{" "}
        <a
          css={css`
            text-decoration: none;
            color: #e5cccc;
          `}
          href={locationLink}
        >
          (map)
        </a>
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
)

Event.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    locationLink: PropTypes.string.isRequired,
  }).isRequired,
}

export default Event
