import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Event from "../components/events/event"

const Events = ({ data }) => {
  const {
    allContentfulEvent: { nodes },
  } = data

  const events = nodes.map(
    ({
      title,
      startTime,
      endTime,
      locationName,
      locationLink,
      image: {
        file: { url },
      },
      description: {
        childMarkdownRemark: { html },
      },
    }) => ({
      title,
      startTime,
      endTime,
      locationName,
      locationLink,
      imageUrl: url,
      description: html,
    })
  )

  return (
    <Layout>
      <SEO title="Events" />
      <div>
        {events.map(
          ({
            title,
            startTime,
            endTime,
            locationName,
            locationLink,
            imageUrl,
            description,
          }) => (
            <Event
              event={{
                title,
                startTime,
                endTime,
                locationName,
                locationLink,
                imageUrl,
                description,
              }}
            />
          )
        )}
      </div>
    </Layout>
  )
}

Events.propTypes = {
  data: PropTypes.shape({
    allContentfulEvent: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query events($lang: String = "en-US") {
    allContentfulEvent(
      filter: { node_locale: { eq: $lang } }
      sort: { fields: startTime }
    ) {
      nodes {
        title
        startTime
        endTime
        locationName
        locationLink
        image {
          file {
            url
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Events
